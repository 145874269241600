import { api } from "@/services/api";
import store from "@/store";

export default {
  state: {
    current_template: {},
    templates: [],
  },
  getters: {
    current_template: (state) => state.current_template,
    templates: (state) => state.templates,
  },
  mutations: {
    current_template_data(state, current_template) {
      state.current_template = current_template;
    },
    list_templates(state, templates) {
      state.templates = templates;
    },
  },
  actions: {
    get_all_templates({ commit }, params) {
      const { is_preview, preview_personify, user } = store.getters;

      let provider;
      if (is_preview) {
        provider = preview_personify.id;
      } else {
        provider = user?.enterprise?.id;
      }

      return new Promise((resolve, reject) => {
        api
          .get("/template/", { ...params, provider })
          .then(({ data }) => {
            commit("list_templates");
            resolve(data);
          })
          .catch((err) => {
            commit("list_templates");
            reject(err);
          });
      });
    },
    get_template({ commit }, params) {
      const id = params.id;
      delete params.id;
      return new Promise((resolve, reject) => {
        api
          .get(`/template/${id}/`, { ...params })
          .then(({ data, status }) => {
            commit("current_template_data", data);
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_template_data");
            reject(err);
          });
      });
    },

    edit_template({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .patch(`/template/${params.id}/`, { ...params })
          .then(({ data, status }) => {
            commit("current_template_data");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_template_data");
            reject(err);
          });
      });
    },
    copy_template(_, id) {
      return new Promise((resolve, reject) => {
        api
          .post(`/template/${id}/copy/`)
          .then(({ data, status }) => {
            resolve({ data, status });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    add_template({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .post("/template/", { ...params })
          .then(({ data, status }) => {
            commit("current_template_data");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_template_data");
            reject(err);
          });
      });
    },

    delete_template({ commit }, id) {
      return new Promise((resolve, reject) => {
        api
          .delete(`/template/${id}/`)
          .then(({ data }) => {
            commit("list_templates");
            resolve(data);
          })
          .catch((err) => {
            commit("list_templates");
            reject(err);
          });
      });
    },
  },
};
